import React from 'react';

import './pricesAndPlans.css'

import { Trans } from '@lingui/macro'

export const PricesAndPlans = () => {

    return (

        // <!-- PRICES -->
        <nav id="pricesAndPlans" className="angle navbar navbar-dark bg-dark pb-0 pt-2 btn-group-vertical" aria-label="">
            <div className="section container btn-group-vertical d-flex m-auto mb-0">
                <div className="col-md-12 d-flex m-auto mt-0 btn-group-vertical">
                    <span className="title text-center text-white d-flex m-auto mb-0"><Trans>Choose the Best for your business</Trans></span>
                    <span className="subtitle text-white d-flex m-auto mt-0 flex-wrap"><Trans>Prices / Plans</Trans></span>
                </div>
            </div>

	        <div id="plans" className="col-md-12 section row pe-0 flex-wrap g-3 m-auto mt-3 mb-0">

		        {/* <!--Free--> */}
                <div id="plan" className="col-md-3 content fadeTop h-100 m-auto mt-4">
                    <div className="card h-100 m-auto mt-0 btn-group-vertical py-2" >
                        <span id="title" className="d-flex m-auto text-white text-center mb-0 pb-0" >Free</span>
                        <span id="subtitle" className="accordion-body text-white text-center m-auto fw-bold px-2 pt-0 pb-2" >(0.00 kr / month)</span>
                        <span id="subtitle" className="accordion-body text-white text-center m-auto fw-normal px-2 pb-1" >Create many invoices as you want and download as PDF. Upgrade to any other plan for accounting web sorware.</span>

                        <div className="d-flex m-auto w-100 pt-4 pb-3">
                            <button className="btn btn-outline-light border-2 fw-bold d-flex m-auto flex-nowrap px-5" type="button" data-bs-toggle="" data-bs-target="#"><Trans>Start</Trans></button>
                        </div>
                    </div>
                </div>

		        {/* <!--Basic--> */}
                <div id="plan" className="col-md-3 content fadeTop after-1 h-100 m-auto mt-4">
                    <div className="card h-100 m-auto mt-0 btn-group-vertical py-2">
                        <span id="title" className="d-flex m-auto text-white text-center mb-0 pb-0" >Basic</span>
                        <span id="subtitle" className="accordion-body text-white text-center m-auto fw-bold px-2 pt-0 pb-0" >(9 kr / month)</span>
                        <span id="subtitle" className="accordion-body text-white text-center m-auto fw-normal px-2 pt-0 pb-2" >Free tryal during 7 days</span>
                        
                        <ul id="items" className="m-auto text-left p-0 px-2" >

                            <li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
                                <img className="d-flex m-auto ms-0 me-2" alt="..." />
                                <span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Accounting web software</span>
                            </li>
                            <li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
                                <img className="d-flex m-auto ms-0 me-2" alt="..." />
                                <span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Unlimited invoices</span>
                            </li>
                            <li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
                                <img className="d-flex m-auto ms-0 me-2" alt="..." />
                                <span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Chat support</span>
                            </li>
                            <li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
                                <img className="d-flex m-auto ms-0 me-2" alt="..." />
                                <span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Unlimited email invoice</span>
                            </li>
                                
                        </ul>

                        <div className="d-flex m-auto w-100 pt-4 pb-3">
                            <button className="btn btn-outline-light border-2 fw-bold d-flex m-auto flex-nowrap px-5"  type="button" data-bs-toggle="" data-bs-target="#"><Trans>Buy</Trans></button>
                        </div>
                    </div>
                </div>
				 
				{/* <!--Standard--> */}
				<div id="plan" className="col-md-3 content fadeTop after-2 h-100 m-auto mt-4">
					<div className="card h-100 m-auto mt-0 btn-group-vertical py-2" >
						<span id="title" className="d-flex m-auto text-white text-center mb-0 pb-0" >Standard</span>
						<span id="subtitle" className="accordion-body text-white text-center m-auto fw-bold px-3 pt-0 pb-0" >(89 kr / month)</span>
						<span id="subtitle" className="accordion-body text-white text-center m-auto fw-normal px-3 pt-0 pb-2" >Free tryal during 30 days</span>
					
						<ul id="items" className="m-auto text-left p-0 px-2" >
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap"  aria-current="page" href="#">Accounting web software</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Unlimited invoices</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Phone & Chat support</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Unlimited email invoice</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">EHF invoice</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Electronic invoice</span>
							</li>
									
						</ul>
				
						<div className="d-flex m-auto w-100 pt-4 pb-3">
							<button className="btn btn-outline-light border-2 fw-bold d-flex m-auto flex-nowrap px-5" type="button" data-bs-toggle="" data-bs-target="#"><Trans>Buy</Trans></button>
						</div>
					</div>
				</div>
		
				{/* <!--Premium--> */}
				<div id="plan" className="col-md-3 content fadeTop after-3 h-100 m-auto mt-4">
					<div className="card h-100 m-auto mt-0 btn-group-vertical py-2" >
						<span id="title" className="d-flex m-auto text-white text-center mb-0 pb-0" >Premium</span>
						<span id="subtitle" className="accordion-body text-white text-center m-auto fw-bold px-2 pt-0 pb-0" >(189 kr / month)</span>
						<span id="subtitle" className="accordion-body text-white text-center m-auto fw-normal px-2 pt-0 pb-2" >Free tryal during 30 days</span>
					
						<ul id="items" className="m-auto text-left p-0 px-2" >
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />	
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap"  aria-current="page" href="#">Accounting web software</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />	
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Unlimited invoices</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />	
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Phone / Chat support</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />	
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Unlimited email invoice</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />	
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">EHF invoice</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />	
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Electronic invoice</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />	
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Multi-profile</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />	
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Projects / Departments</span>
							</li>
							<li className="d-flex m-auto px-0 mt-0 mb-0 list-group-horizontal flex-nowrap align-items-center">
								<img className="d-flex m-auto ms-0 me-2" alt="..." />	
								<span className="plan-item text-white fw-normal pt-0 pb-0 flex-nowrap" aria-current="page" href="#">Auto-reminder tracking</span>
							</li>
									
						</ul>
						
						<div className="d-flex m-auto w-100 pt-4 pb-3">
							<button className="btn btn-outline-light border-2 fw-bold d-flex m-auto flex-nowrap px-5" type="button" data-bs-toggle="" data-bs-target="#"><Trans>Buy</Trans></button>
						</div>
					</div>
				</div>
			</div>

			<img id="logo"  className="d-flex m-auto my-2" alt="..." />

		</nav>
    )
}