import { useEffect, useState } from 'react';
import './App.css';

import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { en, es, no } from 'make-plural/plurals'
//import { en, es, no } from 'make-plural/plurals'

//import { messages as messagesEn } from './locales/en/messages.js';

import { ScrollToTop } from './components/shared/scrollToTop/scrollToTop'
import { Header } from './components/shared/header/header'
import { Features } from './components/features/features'
import { Slider } from './components/slider/slider'
import { ContactUs } from './components/contactUs/contactUs'
import { Footer } from './components/shared/footer/footer'
import { HeroZone } from './components/heroZone/heroZone';

import {messages as enMessage} from './locales/en/messages'
import {messages as esMessage} from './locales/es/messages'
import {messages as noMessage} from './locales/no/messages'
import { PricesAndPlans } from './components/pricesAndPlans/pricesAndPlans';
    
console.log = process.env.NODE_ENV === "development" ? console.log: () => {};

i18n.loadLocaleData({
    en: { plurals: en },
    es: { plurals: es },
    no: { plurals: no }
})
i18n.load({ 
    en: enMessage, 
    es: esMessage, 
    no: noMessage, 
 })
 i18n.activate('en');

function App() {

    const [language, setLanguage] = useState('en');

    useEffect(() => {

        i18n.activate(language);
    }, [language])

    return (

        <I18nProvider i18n={i18n} >

            <main  className="App fw-bold" >
                <ScrollToTop language={language}></ScrollToTop>
                <Header language={language} onChangeLanguage={(lang) => setLanguage(lang)}></Header>

                <HeroZone></HeroZone>
                <Features></Features>
                <PricesAndPlans></PricesAndPlans>
                <Slider></Slider>
                <ContactUs></ContactUs>
                <Footer language={language}></Footer>
            </main>

        </I18nProvider>




        // <div className="App">
        //     <header className="App-header">
        //         <img src={logo} className="App-logo" alt="logo" />
        //         <p>
        //         Edit <code>src/App.js</code> and save to reload.
        //         </p>
        //         <a
        //             className="App-link"
        //             href="https://reactjs.org"
        //             target="_blank"
        //             rel="noopener noreferrer"
        //         >
        //             Learn React
        //         </a>
        //     </header>
        // </div>

    );
}

export default App;
