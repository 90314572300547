import { Trans } from '@lingui/macro'

import './slider.css'

import { changeWindowsLocations } from '../../helpers/tools/changeWindowsLocations'

export const Slider = () => {

    const onTryFroFree = () => {

        changeWindowsLocations.arccountingTryForFree()

        return false;
    } 

    return (
        // <!-- SLIDER -->
        <nav id="slider" className="navbar angle-white bg-white pb-0 pt-2 btn-group-vertical" aria-label="">
            
            <div className="col-md-12 section container btn-group-vertical d-flex m-auto mt-5 mb-0">
                <img id="title" className="d-flex m-auto my-2" alt="..." />
            </div>
            <div className="section container btn-group-vertical d-flex m-auto mb-0">
                <div className="col-md-12 d-flex m-auto mt-0 btn-group-vertical">
                    <span id="title2" className="title2 d-flex m-auto text-center mb-0" ><Trans>Full web accounting management system for all type of business</Trans></span>
                    <div className="d-flex m-auto w-100 pt-4 pb-3">
                        <button id="sliderTryForFree" className="btn btn-primary text-white border-2 btn-lg fw-bold d-flex m-auto flex-nowrap px-5" type="button" data-bs-toggle="" data-bs-target="#" onClick={() => onTryFroFree()}>
                            <Trans>Try for free</Trans>
                        </button>
                    </div>
                </div>	
            </div>
            
        </nav>
    )
}