import './services'
import { services } from './services'


export const documentsServices = {
    getTermsAndConditions: (languange) => {

        return new Promise((resolve, reject) => {

            services.getNoJson('documents/termsAndConditions/' + languange + '.txt')
                // .then(
                //     response => resolve (response),
                //     error => reject(error)
                // )
        })
    }
}