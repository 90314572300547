import { Trans } from '@lingui/macro'

import './contactUs.css'

export const ContactUs = () => {



    return (
        // <!--CONTACT-->
        <nav id="contactUs" className="navbar navbar-dark bg-gradient py-2 btn-group-vertical" aria-label="">
            <div className="section container btn-group-vertical d-flex m-auto mb-0">
                <div className="col-md-12 d-flex m-auto mt-0 btn-group-vertical">
                    <span className="title text-center text-white d-flex m-auto mb-0" ><Trans>Contact us</Trans></span>
                    <span className="subtitle text-white d-flex m-auto mt-0 flex-wrap"><Trans>We can help you</Trans></span>
                </div>
                {/* <!--Formulario--> */}
                <div className="col-md-12 g-3 d-flex m-auto mt-0 pt-4 btn-group-vertical">
                    <div className="col-md-12 row pe-0 d-flex m-auto mt-0">
                        <div id="personalData" className="col-md-6 d-flex m-auto mt-0 btn-group-vertical h-100">
                            <input className="form-control mt-2" type="text" placeholder="Your fullname" aria-label="name"  />
                            <input className="form-control mt-2" type="phone" placeholder="Your phone number" aria-label="phone"  />
                            <input className="form-control mt-2" type="email" placeholder="Your email address" aria-label="email"  />
                        </div>
                        <div id="message" className="col-md-6 d-flex m-auto my-0">
                            <textarea className="form-control border-0 rounded-3 mt-2" type="text" placeholder="Your message here" aria-label="message" ></textarea>
                        </div>
                    </div>
                    <div id="button" className="d-flex m-auto w-100 pt-4 pb-3">
                        <button className="btn btn-outline-light border-2 btn-lg fw-bold d-flex m-auto flex-nowrap px-5" type="button" data-bs-toggle="" data-bs-target="#"><Trans>Submit</Trans></button>
                    </div>
                </div>
            </div>

            <div className="col-md-12 section row pe-0 flex-wrap g-3 m-auto mt-3 mb-0">
            
            </div>

        </nav>
    )
}