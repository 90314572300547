import { Trans } from '@lingui/macro'

import './features.css'

export const Features = () => {
    return (

        // <!-- FEATURES -->
        <nav id="features" className="navbar navbar-light bg-white py-2 btn-group-vertical" aria-label="">

            <div className="section feature container btn-group-vertical d-flex m-auto mb-0">
                <div className="col-md-12 d-flex m-auto mt-0 btn-group-vertical">
                    <span className="title text-dark d-flex m-auto mb-0" ><Trans>Why choose Inure</Trans></span>
                    <span className="subtitle text-dark d-flex m-auto mt-0 flex-wrap"><Trans>Features &nbsp; Services</Trans></span>
                </div>
            </div>

            <div className="section col-md-12 row pe-0 flex-wrap g-3 m-auto mt-3 mb-5">

                <div className="col-md-4 content fadeTop m-auto mt-4">
                    <div className="ft d-flex m-auto h-100 mt-0 btn-group-vertical">
                        <img id="ft_profile" className="d-flex m-auto mb-2" alt="..."/>
                        <span className="title d-flex m-auto text-center pb-1 mb-0" ><Trans>Multi-profile</Trans></span>
                        <span className="description accordion-body m-auto text-center fw-normal px-3 py-1" ><Trans>Single user as an accounting service provider, can manage multiple accounts by creating tailored profiles to each client.</Trans></span>
                    </div>
                </div>

                <div className="col-md-4 content fadeTop m-auto mt-4">
                    <div className="ft d-flex m-auto h-100 mt-0 btn-group-vertical">
                        <img id="ft_invoice" className="d-flex m-auto mb-2" alt="..."/>
                        <span className="title d-flex m-auto text-center pb-1 mb-0" ><Trans>Invoices / Accounting</Trans></span>
                        <span className="description accordion-body m-auto text-center fw-normal px-3 py-1" ><Trans>Create, send or receive digital invoices and manage your accounting tasks. All payments are automatically registered on each profile.</Trans></span>
                    </div>
                </div>

                <div className="col-md-4 content fadeTop m-auto mt-4">
                    <div className="ft d-flex m-auto h-100 mt-0 btn-group-vertical">
                        <img id="ft_bank" className="d-flex m-auto mb-2" alt="..."/>
                        <span className="title d-flex m-auto text-center pb-1 mb-0" ><Trans>Connected to the Bank</Trans></span>
                        <span className="description accordion-body m-auto text-center fw-normal px-3 py-1" ><Trans>Pay invoices at the same time you post them without logging into online banking. You save spent time on banking services.</Trans></span>
                    </div>
                </div>

                <div className="col-md-4 content fadeTop m-auto mt-4">
                    <div className="ft d-flex m-auto h-100 mt-0 btn-group-vertical">
                        <img id="ft_tax" className="d-flex m-auto mb-2" alt="..."/>
                        <span className="title d-flex m-auto text-center pb-1 mb-0" ><Trans>Tax Record</Trans></span>
                        <span className="description accordion-body m-auto text-center fw-normal px-3 py-1" ><Trans>Track tax paid from your employer's submission to ensure you are paying the correct tax so there won't be surprises at the end of the year.</Trans></span>
                    </div>
                </div>
            
                <div className="col-md-4 content fadeTop m-auto mt-4">
                    <div className="ft d-flex m-auto h-100 mt-0 btn-group-vertical">
                        <img id="ft_payroll" className="d-flex m-auto mb-2" alt="..."/>
                        <span className="title d-flex m-auto text-center pb-1 mb-0" ><Trans>Easy Payroll</Trans></span>
                        <span className="description accordion-body m-auto text-center fw-normal px-3 py-1" ><Trans>Stay up-to-date on wages, send payroll to employees and notificate with the Inure payroll system.</Trans></span>
                    </div>
                </div>
            
                <div className="col-md-4 content fadeTop m-auto mt-4">
                    <div className="ft d-flex m-auto h-100 mt-0 btn-group-vertical">
                        <img id="ft_responsive" className="d-flex m-auto mb-2" alt="..." />
                        <span className="title d-flex m-auto text-center pb-1 mb-0" ><Trans>AI &nbsp; Responsive UI</Trans></span>
                        <span className="description accordion-body m-auto text-center fw-normal px-3 py-1" ><Trans>Provide a responsive interface for several devices. Submit receipts from mobile and Artificial Intelligence interprets all data for you.</Trans></span>
                    </div>
                </div>

            </div>
            
        </nav>
    )
}