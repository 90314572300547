
import * as enviroment from '../../enviroment'

export const changeWindowsLocations = {

    arccountingTryForFree: () => {

        const startUrl = enviroment[process.env.NODE_ENV].APP_ACCOUNTING_TRY_FOR_FREE_URL
        window.location.href = startUrl
    }, 

    accountingStart: (clientEmail) => {

        const startUrl = enviroment[process.env.NODE_ENV].APP_ACCOUNTING_START_URL
        window.location.href = startUrl + '/' + clientEmail
    }
} 