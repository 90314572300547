
export const development = {
    //API_URL: 'http://localhost:9013/'
    //API_URL: 'http://localhost:3001/'
    BASE_URL: 'http://localhost:3000/',
    API_URL: 'http://localhost:9021/',
    APP_ACCOUNTING_START_URL: 'http://localhost:3001/login',
    APP_ACCOUNTING_TRY_FOR_FREE_URL: 'http://localhost:3001/register'
};
  
export const production = {
    //API_URL: 'http://localhost:3001/'
    //API_URL: 'https://strange-metrics-290121.ey.r.appspot.com/'
    //API_URL: 'http://47.91.93.46:8080/'
    BASE_URL: 'http://www.inure.no/', 
    API_URL: 'http://www.backend.inure.no/',
    APP_ACCOUNTING_START_URL: 'http://www.accounting.inure.no/login',
    APP_ACCOUNTING_TRY_FOR_FREE_URL: 'http://www.accounting.inure.no/register'
};
  
export const test = {
    BASE_URL: 'http://localhost:3000/',
    API_URL: 'http://localhost:3001/',
    APP_ACCOUNTING_START_URL: 'http://localhost:3001/login',
    APP_ACCOUNTING_TRY_FOR_FREE_URL: 'http://localhost:3001/register'
};

export default development;
