
import './scrollToTop.css'

export const ScrollToTop = () => {

    return (
        <div id="scrollToTop" className="rounded-pill bg-primary" >
            <img alt="..." />
        </div>
    )
}