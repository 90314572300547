import './header.css'

import { Trans } from '@lingui/macro'

import { changeWindowsLocations } from '../../../helpers/tools/changeWindowsLocations'

export const Header = ({language, onChangeLanguage }) => {

    const onTryFroFree = () => {

        changeWindowsLocations.arccountingTryForFree()

        return false;
    } 

    return (

        <main> 
            <nav className="navbar navbar-expand-md navbar-dark bg-dark align-items-center py-3" aria-label="">
                <div id="headerContent" className="container-fluid section" >
                    <button className="navbar-toggler me-0 align-items-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarInure" aria-controls="navbarInure" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse bg-transparent col-md-12 row" id="navbarInure">
                        
                        <a className="navbar-brand col-md-3 m-auto" href="#heroZone">
                            <img className="d-flex m-auto" alt="..." />
                        </a>

                        <div className="menu-option col-md-1 dropdown">
                            <a className="btn btn-secondary btn-sm dropdown-toggle dropdown-toggle" href="/#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                {language}
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <li><a className="dropdown-item" href="/#" onClick={() => onChangeLanguage('es')}>es</a></li>
                                <li><a className="dropdown-item" href="/#" onClick={() => onChangeLanguage('en')}>en</a></li>
                                <li><a className="dropdown-item" href="/#" onClick={() => onChangeLanguage('no')}>no</a></li>
                            </ul>
                        </div>

                        <div className="menu col-md-8 row pe-0 d-flex fw-normal" >
                            <a className="menu-option col-md-3 link d-flex" href="#features">
                                <span className="d-flex m-auto text-center pb-1" ><Trans>Our services</Trans></span>
                            </a>
                            <a className="menu-option col-md-3 link d-flex" href="#pricesAndPlans">
                                <span className="d-flex m-auto text-center pb-1" ><Trans>Prices / Plans</Trans></span>
                            </a>
                            <a className="menu-option col-md-3 link d-flex" href="#contactUs">
                                <span className="d-flex m-auto text-center pb-1" ><Trans>Contact us</Trans></span>
                            </a>

                            <button className="menu-option col-md-3 btn btn-outline-light border-2 fw-bold d-flex m-auto flex-nowrap" type="button" data-bs-target="">
                                <span className="m-auto" onClick={() => onTryFroFree()}><Trans>Try for Free</Trans></span>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </main>
    )
}