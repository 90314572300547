import { useEffect, useState } from "react"

import { Trans } from '@lingui/macro'

//import { documentsServices } from '../../../services/documents'

export const Security = ({
                                        show, language,

                                        onClose
                                   }) => {

    const [security, setSecurity] = useState('')

    useEffect(() => {

        //documentsServices.getTermsAndConditions(language)
        //    .then(text => setTermsAndConditions(text))

    }, [show, language])

    //console.log(security)

    return (
        
        <div className={`modal fade ${show && 'show'}`} style={{ display: show && 'block'}} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title" id="staticBackdropLabel">
                            <Trans>Security</Trans>
                        </h2>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => onClose()}></button>
                    </div>
                    <div className="modal-dialog modal-dialog-scrollable">
                        {security}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => onClose()}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}