import * as enviroment from '../enviroment'

const baseUrl = enviroment[process.env.NODE_ENV].BASE_URL

export const services = {

    getNoJson: (url, ) => {

        return fetch(baseUrl + url)
                .then(response => { console.log(response); return response;/*.json()*/ } )
                //.then(data => console.log(data));
    },

    get: (url, ) => {

        return fetch(baseUrl + url)
                .then(response => response.json())
                //.then(data => console.log(data));
    }
}