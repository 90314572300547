import { useState } from "react"

import { Trans } from '@lingui/macro'

import { TermsAndConditions } from '../documents/termsAndConditions'
import { Security } from '../documents/security'
import { PrivacyPolicy } from '../documents/privacyPolicy'
import { Cookies } from '../documents/cookies'

import './footer.css'

export const Footer = ({language}) => {

    const [showTermAndConditions, setShowTermAndConditions] = useState()
    const [showSecurity, setShowSecurity] = useState()
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState()
    const [showCookies, setShowCookies] = useState()

    return (
        <>

            <nav id="footer" className="navbar d-flex m-auto pt-4 pb-0 btn-group-vertical" aria-label="" >
                <div className="section container btn-group-vertical">
                    <img className="logo-footer section d-flex m-auto ms-0 py-0" alt="..." />

                    <div className="content col-md-12 row pe-0 pt-0 pb-2 d-flex m-auto" >
                        {/* <!--Services--> */}
                        <ul className="services col-md-3 d-flex m-auto mx-0 pt-3 ps-0 btn-group-vertical">
                            <li className="services-title d-flex p-0 " ><Trans>Services</Trans></li>
                            <li className="d-flex mt-2 mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap"  aria-current="page" href="/#"><Trans>Invoices</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap" aria-current="page" href="/#"><Trans>Accounting</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap" aria-current="page" href="/#"><Trans>Sales / Purchasing</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap"  aria-current="page" href="/#"><Trans>Payroll</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap"  aria-current="page" href="/#"><Trans>Tax Track</Trans></a>
                            </li>
                            
                        </ul>

                        {/* <!--Resources--> */}
                        <ul className="resources col-md-3 d-flex m-auto mx-0 my-0 pt-3 ps-0 btn-group-vertical justify-content-start">
                            <li className="resources-title d-flex p-0" ><Trans>Resources</Trans></li>
                            <li className="d-flex mt-2 mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap" aria-current="page" href="/#"><Trans>Chat / Call Back</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap" aria-current="page" href="/#"><Trans>Knowledge articles</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap" aria-current="page" href="/#"><Trans>Accounting dictionary</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap" aria-current="page" href="/#"><Trans>FAQ's</Trans></a>
                            </li>
                        </ul>

                        {/* Inures */}
                        <ul className="inures col-md-3 d-flex m-auto mx-0 ps-0 pt-3 btn-group-vertical">
                            <li className="inures-title d-flex p-0" ><Trans>Inure</Trans></li>
                            <li className="d-flex mt-2 mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap"  aria-current="page" href="/#"><Trans>About us</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap" aria-current="page" href="/#"><Trans>Our staff</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap" aria-current="page" href="/#"><Trans>User comments</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap" aria-current="page" href="/#"><Trans>Buy a Plan</Trans></a>
                            </li>
                            <li className="d-flex mb-2">
                                <a className="link2 nav-link m-auto p-0 fw-normal flex-nowrap" aria-current="page" href="#contactUs"><Trans>Contact us</Trans></a>
                            </li>
                        </ul>

                        <img  className="logo2-footer d-flex m-auto me-0 mt-0" alt="..." />
                    </div> 

                    {/* separador */}
                    
                    <div className="col-md-12 row pe-0 d-flex m-auto list-group-horizontal justify-content-between py-3">
                        {/* Legal links */}
                        <div id="legal-links" className="col-md-6 d-flex m-auto list-group-horizontal justify-content-between ms-0">
                            <a className="link2 nav-link m-auto p-0 fw-normal fst-italic flex-nowrap" aria-current="page" href="/#!" onClick={() => setShowTermAndConditions(true)}><Trans>Terms and Conditions</Trans></a>
                            <a className="link2 nav-link m-auto p-0 fw-normal fst-italic flex-nowrap" aria-current="page" href="/#!" onClick={() => setShowSecurity(true)}><Trans>Security</Trans></a>
                            <a className="link2 nav-link m-auto p-0 fw-normal fst-italic flex-nowrap" aria-current="page" href="/#!" onClick={() => setShowPrivacyPolicy(true)}><Trans>Privacy policy</Trans></a>
                            <a className="link2 nav-link m-auto p-0 fw-normal fst-italic flex-nowrap" aria-current="page" href="/#!" onClick={() => setShowCookies(true)}><Trans>Cookies</Trans></a>
                        </div>
                        {/* Social media */}
                        <div id="social" className="col-md-3 d-flex m-auto list-group-horizontal justify-content-between me-0">
                            <a className="d-flex m-auto p-0" aria-current="page" href="/#">
                                <img id="social_fb" className="section d-flex m-auto py-0" alt="..." />
                            </a>
                            <a className="d-flex m-auto p-0" aria-current="page" href="/#">
                                <img id="social_ig" className="section d-flex m-auto py-0" alt="..." />
                            </a>
                            <a className="d-flex m-auto p-0" aria-current="page" href="/#">
                                <img id="social_yt" className="section d-flex m-auto py-0" alt="..." />
                            </a>
                            <a className="d-flex m-auto p-0" aria-current="page" href="/#">
                                <img id="social_tw" className="section d-flex m-auto py-0" alt="..." />
                            </a>
                            <a className="d-flex m-auto p-0" aria-current="page" href="/#">
                                <img id="social_in" className="section d-flex m-auto py-0" alt="..." />
                            </a>
                        </div>
                    </div>

                </div>
                {/* copyright */}
                <div id="copyright" className="w-100 navbar d-flex m-auto py-2" >
                    <div className="section container d-flex m-auto">
                        <span className="d-flex m-auto fst-italic fw-normal text-center" ><Trans>Powered by Inure Corp. LLC © 2022 - All rights reserved</Trans></span>
                    </div>
                </div>
            </nav> 

            <TermsAndConditions show={showTermAndConditions} language={language} onClose={() => setShowTermAndConditions(false)}></TermsAndConditions>
            <Security show={showSecurity} language={language} onClose={() => setShowSecurity(false)}></Security>
            <PrivacyPolicy show={showPrivacyPolicy} language={language} onClose={() => setShowPrivacyPolicy(false)}></PrivacyPolicy>
            <Cookies show={showCookies} language={language} onClose={() => setShowCookies(false)}></Cookies>

        </>
    )
}