import React, { useState } from 'react';
import { useForm}  from 'react-hook-form';

import { Trans } from '@lingui/macro'

import './heroZone.css'

import { changeWindowsLocations } from '../../helpers/tools/changeWindowsLocations'

export const HeroZone = () => {
    
    const [clientEmail, setClientEmail] = useState()

    const { register, handleSubmit, formState: { errors } } = useForm()

    function onSubmit(values) {
    
        changeWindowsLocations.accountingStart(clientEmail)
        return false;
    }

    return (

        // <!-- HEROZONE -->
        <nav id="heroZone" className="angle navbar navbar-dark bg-dark pb-0" aria-label="" >
            <div className="section hero container col-md-12 btn-group-vertical d-flex m-auto mb-0">
                <div className="col-md-6 d-flex m-auto btn-group-vertical ms-0">
                    <img className="hero-logo m-auto mb-4" alt="..."  />
                    <span id="hero-logo-title" className="title d-flex m-auto ms-0 mb-3" ><Trans>The Future of Accounting</Trans></span>
                    <span id="hero-logo-text" className="text-hero d-flex m-auto mt-0 flex-wrap" >
                        <Trans>Inure System provides accounting web services for Accounting managers, Sole Traders and Self-Employed workers. The solution is integrated with Artificial Intelligence that would reduce your time being spent on your day-to-day administrative tasks.</Trans>
                        <br/>
                        <Trans>Automatically processes your accounting data extracted from a wide variety of receipts and invoices by using AI.</Trans>
                    </span>
                </div>
                <div className="img-hero d-flex m-auto btn-group-vertical me-0">
                    <img id="hero_1" className="fade-3 d-flex m-auto layer-3" alt="..." />
                    <img id="hero_2" className="fade-2 d-flex m-auto layer-2" alt="..." />
                    <img id="hero_3" className="fade-1 d-flex m-auto layer-1" alt="..." />
                    <img id="hero_cell" className="layer-slide-right d-flex m-auto layer me-0" alt="..." />
                </div>

                <form id="start-form" className="col-md-6 row g-3 d-flex m-auto list-group-horizontal" onSubmit={handleSubmit(onSubmit)}>
                    <div className="start-input col-md-9 d-flex m-auto">
                        <input  className={`form-control ${errors.clientEmail && 'field-has-error'}`} 
                                type="email" 
                                //id="clientEmail"
                                //name="clientEmail"
                                placeholder="Your email address" 
                                aria-label="email"
                                defaultValue={clientEmail}
                                
                                {...register("clientEmail", { 
                                                                required: true,
                                                                //min: 1,
                                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                onChange: (e) => setClientEmail(e.target.value) 
                                                            }
                                )}
                                //ref={register({required: true /*, pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}*/})} 
                            />

                    </div>
                    <div className="col-md-3 d-flex m-auto px-0">
                        <button className={`btn btn-primary border-2 btn-lg d-flex m-auto px-4 ${errors.clientEmail && 'disabled'}`} type="submit" >
                            <span className="d-flex m-auto text-white fw-bold"><Trans>Start</Trans></span>
                        </button>
                    </div>
                </form>

            </div> 
        </nav> 
    )
}